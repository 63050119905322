import React from 'react'

const Footer = () => {

    return ( 

        
        <div className="tinyText center">
        v0.0.26 - © - made by <a href="https://twitter.com/raphascartezini" target="_blank"><b>Raphael Scartezini</b></a>
        <p>This is a PWA(Progressive Web App). It means you can use it as a smartphone app!
For iOS, open this site in Safari and select "Add to Home Screen".
For Android, on Chrome, either you'll be prompted for installation or you can open this site and select "Add to Home Screen".

</p>
        </div>
     );
     
}



 
export default Footer;