import React , { useState, useContext } from 'react';
import { projectFirestore, timestamp } from '../firebase/FirebaseConfig'; 
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import firebase from 'firebase/app' 

firebase.analytics().logEvent('add-new-form-opened');
const API_KEY = `${process.env.REACT_APP_GEOCODE_API_KEY}`;

const AddNewDonutPlace = () => {
    let history = useHistory();
    //const { getCoordinatesFromAddress } = useContext(MapsContext)
    const [ placeName, setPlaceName ] = useState('');
    const [ placeAddress, setPlaceAddress ] = useState('');
    const [ placeDetails, setPlaceDetails ] = useState('');

     //TODO: DELETE THIS and hide key //https://maps.googleapis.com/maps/api/geocode/json?address=Fontes+Pereira+de+melo+176+montijo&key=AIzaSyDT4Oih_KJQtqbIoc9C9B-5dQocabhAb60

  const getCoordinatesFromAddress = (placeAddress) => 
        axios.get('https://maps.googleapis.com/maps/api/geocode/json?address='+ placeAddress +'&key='+API_KEY)
        .then(function (response) {
            firebase.analytics().logEvent('add-new-form-submitted');
            // handle success
            console.log('first',response);
            prepareAddressToInsert(response)            
            //console.log(response.data.results[0].address_components);
               
        })
        .catch(function (error) {
            // handle error
            console.log(error)  //Axios entire error message
        })
        
    const prepareAddressToInsert = (response) => {

        if(response.data.status === 'OK'){
            console.log('second', response);

            let newPlaceData = response.data.results[0].address_components
            console.log('third', newPlaceData);

            let placeNumber = null
           if( newPlaceData.find(element => element.types.includes('street_number')) ){
            placeNumber = newPlaceData.find(element => element.types.includes('street_number')).long_name
           }
           let placeZip = null
           if( newPlaceData.find(element => element.types.includes('postal_code')) ){
            placeZip = newPlaceData.find(element => element.types.includes('postal_code')).long_name
           }
        
            let preparedAddress = {
                placeName: placeName, 
                placeSubmittedAddress: placeAddress,
                placeAddress: response.data.results[0].formatted_address, 
                placeLat:response.data.results[0].geometry.location.lat,
                placeLng:response.data.results[0].geometry.location.lng,
                placeStreet:newPlaceData.find(element => element.types.includes('route')).long_name ,//.address_components[1].long_name,
                placeNumber:placeNumber,
                placeCity:newPlaceData.find(element => element.types.includes('administrative_area_level_2') || element.types.includes('locality')).long_name ,//.address_components[3].long_name,
                //placeState:newPlaceData.find(element => element.types === 'route') ,//.address_components[4].long_name, 
                placeCountry:newPlaceData.find(element => element.types.includes('country')).long_name,//.address_components[5].long_name,
                placeZip:placeZip ,//.address_components[6].long_name,
                placeDetails: placeDetails,
                placeCreatedAt: new Date(),
                isAuthorized: false
            }

            console.log('fourth', preparedAddress);
            saveToFirestore(preparedAddress)
            firebase.analytics().logEvent('add-new-form-submitted-accepted');


        }else{
            
        }

        

        }

        

            
    

    const handleSubmit = (e) =>{
        e.preventDefault();
        console.log('placeAddress',placeAddress)
        getCoordinatesFromAddress(placeAddress)

        //TODO: get an api to grab address and find coordinates, showing greefield on success
        //TODO: check snazzy maps to stye the map later   
        }

    const saveToFirestore = (preparedAddress) => {

        projectFirestore.collection('donutPlaces').add(preparedAddress).then(()=>{
                //props.history.push('/');
                history.push('/find')
            })
            .catch(function (error) {
            // handle error
            //console.log(error)  //Axios entire error message
            console.log(error) //Google API error message 
        })


    }    
        
    
    
    return ( 
        <form onSubmit={handleSubmit}>
        <div className="input-field center">
        <h3>Suggest a place</h3>
        <p>Your place is not in the list? Let us know!</p>
        </div>
        <div className="input-field white">
            <input type="text" placeholder="Name of the place" value ={placeName}
                onChange={(e) => setPlaceName(e.target.value)}  />
        </div>
        <div className="input-field white">

            <input type="text" className="materialize-textarea" placeholder="like: 2146 Leghorn St, Mountain View" value ={placeAddress}
                onChange={(e) => setPlaceAddress(e.target.value)} / >
        </div>   
        <div className="input-field white">     
            <input type="text" className="materialize-textarea" placeholder="Add more details if you like" value ={placeDetails}
                onChange={(e) => setPlaceDetails(e.target.value)}  />
        </div>   
        <div className="input-field  center">     
            <button className="btn pink-glace">Send Suggestion</button>
            <p> It takes a while to get published because: verified before</p>
        </div>  
           
            

        </form>
     );
}
 
export default AddNewDonutPlace;