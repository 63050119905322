import React, {useState, useEffect, useContext} from 'react';
import { projectFirestore } from '../firebase/FirebaseConfig';
import {LocationContext} from '../contexts/LocationContext'
import { useLocation } from 'react-router-dom';


function useDonutPlace(){
    const [donutPlace, setDonutPlace] = useState([])     
    //const {currentUserCity} = useContext(LocationContext)   
    let location = useLocation();
    const donutPlaceID2  = location.state.DonutPlaceID
    

    //console.log('location', location)

   
    useEffect(() =>{

        console.log('location.state.donutPlaceID', location.state.donutPlaceID)

        const unsubscribe = projectFirestore.collection('donutPlaces').doc(location.state.donutPlaceID)
        .get()
        .then((docRef) => { 
            console.log('docRef',docRef.data()) 
            setDonutPlace(docRef.data())
        })
        .catch((error) => { console.log('error',error)})

        
            return () => unsubscribe
    }, [])

    return donutPlace
}

const DonutPlaceDetail = () => {

    const {getDistanceFromLatLonInKm, currentUserCity, coords} = useContext(LocationContext)
    console.log('list coords', coords)
    const donutPlace = useDonutPlace()

    return (         
        <div className="donutPlace">                 
            <div className="donut-card z-depth-2 pink lighten-4">
                <span className="card-title">{donutPlace.placeName}</span>
                <div className="placeAddress">{donutPlace.placeStreet}, {donutPlace.placeNumber} - {donutPlace.placeCity}</div>
            </div>
            <div className="donut-card-bottom z-depth-2 orange lighten-4">                                
                <span className="distance"><b> distance:</b>{getDistanceFromLatLonInKm(coords.coords.latitude, coords.coords.longitude, donutPlace.placeLat,donutPlace.placeLng)}</span>
            </div>           
        </div>
    )
}


export default DonutPlaceDetail