import NavBar from './components/NavBar';
import { BrowserRouter, Route } from 'react-router-dom';
import DonutPlacesList from './components/donut-places-list';
import DonutPlaceDetail from './components/donut-place-detail';
import Home from './components/Home'
import AddNewDonutPlace from './components/add-new-donut-place-form';
import LocationContextProvider from './contexts/LocationContext';
import Footer from './components/Footer';


function App() {
  
  return (
    <BrowserRouter >
    <div className="App container">       
      <NavBar />  
      <LocationContextProvider >        
      <Route exact path='/' component={Home}/>          
      <Route path='/find' component={DonutPlacesList}/>  
      <Route path='/place/' component={DonutPlaceDetail}/>  
      <Route path='/suggest' component={AddNewDonutPlace}/>  
      </LocationContextProvider>
      <Footer />
      
    </div>
    </BrowserRouter>
  );
}

export default App;
