import React, {useState, useEffect, useContext} from 'react';
import { projectFirestore } from '../firebase/FirebaseConfig';
import {LocationContext} from '../contexts/LocationContext'
import { Link } from 'react-router-dom';
import firebase from 'firebase/app' 

function useDonutsPlaces(){
    const [donutPlaces, setDonutsPlaces] = useState([]) 
    const {getDistanceFromLatLonInKm, currentUserCity, coords} = useContext(LocationContext)
    
   
    useEffect(() =>{
        
        const unsubscribe = projectFirestore.collection('donutPlaces')
            .where('placeCity', '==', currentUserCity)
            .where('isAuthorized', '==', true)
            .onSnapshot((snapshot) => {
                const newDonutPlaces = snapshot.docs.map( (doc) => ({
                    id: doc.id,
                    distance: getDistanceFromLatLonInKm(coords.coords.latitude, coords.coords.longitude, doc.data().placeLat,doc.data().placeLng),
                    ...doc.data()
                }))
                setDonutsPlaces(newDonutPlaces)
            })
            return () => unsubscribe
    }, [])
    return donutPlaces
}

const DonutPlacesList = () => {    

    const {getDistanceFromLatLonInKm, currentUserCity, coords} = useContext(LocationContext)
    //console.log('list coords', coords)
    const donutPlaces = useDonutsPlaces()
    firebase.analytics().logEvent('list shown:' + donutPlaces.length);   

    return donutPlaces.length ? ( 
        
        <div className="donutPlaceList">
        <p className="center">Currently <b>{donutPlaces.length}</b> donut shop(s) near {currentUserCity}</p>
            <ul>
                {donutPlaces.sort((a, b) => a.distance - b.distance).map(donutPlace => {
                    
                    let goToGoogleMaps2 = "http://maps.google.com/maps?" + "saddr="+ coords.coords.latitude+","+coords.coords.longitude + "&daddr="+ donutPlace.placeLat+","+donutPlace.placeLng;
                    //console.log('goToGoogleMaps2', goToGoogleMaps2)
                    return (           
                        <li className="donut-list-item" key={donutPlace.id}>
                            <div className="donut-card z-depth-2 pink lighten-4">
                                <span className="card-title">{donutPlace.placeName}</span>
                                <div className="placeAddress">{donutPlace.placeStreet}, {donutPlace.placeNumber} - {donutPlace.placeCity}</div>                                
                            </div>
                            <div className="donut-card-middle z-depth-2 white ">
                                <a className="link_active" href={goToGoogleMaps2} target="_blank">See on Google Maps</a>
                            </div>
                            <div className="donut-card-bottom z-depth-2 orange lighten-4">                                
                                <span className="distance"><b> Distance:</b>{donutPlace.distance} Km</span>
                            </div>
                        </li>
                                             
                        );
                })}
            </ul>

             
        </div>

     ) : (
        <div>
            <p> :-( no donut places near {currentUserCity}</p>
        </div>
    )
}


export default DonutPlacesList