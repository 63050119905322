// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app' 

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
//import "firebase/auth";
import "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
    authDomain: "donutradar.firebaseapp.com",
    databaseURL: "https://donutradar.firebaseio.com",
    projectId: "donutradar",
    storageBucket: "donutradar.appspot.com",
    messagingSenderId: "659190030",
    appId: "1:659190030:web:fda2daddab0358f345675f",
    measurementId: "G-G7P0GJQM1N"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

//const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {firebase,  projectFirestore, timestamp };



