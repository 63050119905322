import React from 'react'
import { Link } from 'react-router-dom';
import logotext from '../donut-radar-type-large-2.svg'

const NavBar = () => {

    return ( 
        
        <div className="logo-menu-top">
            <div className="navbar">
            <img src={logotext} className="img-responsive " alt="logotext" /> 
            <ul className="list-menu-top menu-horizontal">
            <li ><Link to={'/' } className="btn brown darken-2">Home</Link></li>
            <li ><Link to={'/find' } className="btn brown darken-2">Find</Link></li>
            <li ><Link to={'/suggest' } className="btn brown darken-2">Suggest</Link></li>
            </ul>
            </div>
        </div>
     );
     
}



 
export default NavBar;